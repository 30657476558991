import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
// import { graphql, Link as GatsbyLink } from "gatsby";
import "./index.css";
import Header from "../sections/A_Header";
import Hero from "../sections/B_Hero";
//
import CallToActionSmall from "../sections/C_CTA_Small";
import About from "../sections/D_About";
import HowItWorks from "../sections/E_How_It_Works";
import Automate from "../sections/F_Robot_Showcase";
import Software from "../sections/Fa_Robot_Software";
import Journey from "../sections/G_Journey";
import Testimonials from "../sections/H_Testimonials";
import Team from "../sections/I_Team";
import CallToActionEmail from "../sections/J_CTA_Email_Form";
import Footer from "../sections/K_Footer";
import Layout from "../components/Layout";
import RecipeSlider from "../components/RecipeSlider";
import Values from "../sections/Values";
import OurSuccessCase from "../sections/M_Our_Success_Case";
import Intro from "../sections/N_Intro";
import CDTI from "../assets/cdti.webp";
import UE from "../assets/ue.webp";
import PRTR from "../assets/prtr.webp";

const Wrapper = styled.div``;

const BlueWrapper = styled.div`
  background: #1b2033;
  overflow: hidden;
`;

const Subsidies = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 75px;
`;

const SubsidiesLogos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;

  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const EULogo = styled.img`
  width: 370px;
  height: 100px;
  object-fit: contain;
  margin-top: 8px;
  margin-left: 95px;
`;

const CDTILogo = styled.img`
  width: 465px;
  height: 120px;
  object-fit: contain;
`;

const PRTRLogo = styled.img`
    width: 465px;
    height: 120px;
    object-fit: contain;
`;

const SubsidiesNotice = styled.span`
  width: 530px;
  text-align: justify;
  line-height: 1.4;
  font-size: 12px;

  & b{
    font-weight: 800;
  }
`;

const IndexPage = () => {
  // const { t } = useTranslation();
  return (
    <Layout pageTitle="WeTechFood">
      <Wrapper id="home">
        <Header />
        <Hero />
        <Intro />
        {/* <CallToActionSmall /> */}
        <Automate />
        <Software />
        <OurSuccessCase />
        {/* <BlueWrapper><RecipeSlider /></BlueWrapper> */}
        <Testimonials />
        <Values />
        <Team />
        <Subsidies>
          <SubsidiesLogos>
            <a href="https://next-generation-eu.europa.eu/">
              <EULogo src={UE} alt="Financiado por la UE" />
            </a>
            <div>
                <a href="https://www.cdti.es/">
                  <CDTILogo src={CDTI} alt="Subencionado por CDTI" />
                </a>
                <SubsidiesNotice>
                    El proyecto <b>“Robot inteligente para mejoras de procesos en restauración mediante
                    técnicas de Machine Learning” (SNEO-20222133)</b> está subvencionado por el CDTI 
                    (Centro para el Desarrollo Tecnológico Industrial) dentro del programa NEOTEC de 
                    “Ayudas destinadas a nuevos proyectos empresariales de empresas innovadoras”. 
                    Se desarrolla entre <b>2023 y 2024</b> y cuenta con la financiación del CDTI y la Unión 
                    Europea a través de los fondos Next Generation UE, en el marco del Plan de 
                    Recuperación, Transformación y Resiliencia (PRTR) del Gobierno de España.
                </SubsidiesNotice>
              </div>
              <a href="https://planderecuperacion.gob.es/">
                <PRTRLogo src={PRTR} alt="Plan de recuperación" />
              </a>
          </SubsidiesLogos>
        </Subsidies>
        <Footer />
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
